<template>
    <div class="menu ch ">
        
        <!-- <div style="padding:0 20px">
            <router-link class="item" v-for="(item, index) in dept" :key="index" :to="item.link">{{t(tools.title(item.id))}}</router-link>
        </div> -->
        <!-- <div style="padding:0 20px"><Mode :dark="db.settings.dark" @change="db.settings.setMode($event)"></Mode></div> -->
        
        <!-- <router-link class="item" to="/">Reshaper</router-link>
        
        <div v-if="db.profile.me.id" class="ch right">            
            <svg class="hover click pointer" @click="showSupport = true" width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.49398 15.0316C4.63553 15.0316 4.76527 14.9942 4.88323 14.9193C5.00118 14.8491 5.14272 14.7368 5.30786 14.5825L7.3673 12.6807C7.16914 12.5263 6.99929 12.3041 6.85775 12.014C6.71621 11.724 6.64544 11.2936 6.64544 10.7228V6.07018C6.64544 5.35439 6.78462 4.74152 7.06299 4.23158C7.34607 3.71696 7.74711 3.32398 8.2661 3.05263C8.78981 2.77661 9.4126 2.6386 10.1345 2.6386H15.0885L15.0814 2.37895C15.0436 1.89708 14.9163 1.47836 14.6992 1.12281C14.4822 0.767251 14.1779 0.491228 13.7863 0.294737C13.3994 0.0982456 12.937 0 12.3992 0H2.68931C1.8778 0 1.2267 0.231579 0.736023 0.694737C0.245341 1.15322 0 1.80117 0 2.6386V9.52982C0 10.3626 0.245341 11.0269 0.736023 11.5228C1.2267 12.0187 1.8778 12.2667 2.68931 12.2667H3.86412V14.3158C3.86412 14.531 3.91838 14.7041 4.02689 14.8351C4.14013 14.9661 4.29582 15.0316 4.49398 15.0316ZM15.9306 16C16.1288 16 16.2821 15.9345 16.3907 15.8035C16.5039 15.6725 16.5605 15.4994 16.5605 15.2842V13.2421H17.3107C18.1175 13.2421 18.7662 12.9942 19.2569 12.4982C19.7523 11.9977 20 11.331 20 10.4982V6.23158C20 5.39415 19.7523 4.7462 19.2569 4.28772C18.7662 3.82924 18.1175 3.6 17.3107 3.6H10.2972C9.4527 3.6 8.79453 3.83158 8.32272 4.29474C7.85091 4.75322 7.615 5.40117 7.615 6.2386V10.4982C7.615 11.331 7.85091 11.9977 8.32272 12.4982C8.79453 12.9942 9.4527 13.2421 10.2972 13.2421H12.5619L15.1168 15.5579C15.2866 15.7076 15.4282 15.8175 15.5414 15.8877C15.6594 15.9626 15.7891 16 15.9306 16Z" fill="var(--g3)"/></svg>

            <router-link class="item" to="/credits">{{db.profile.me.credits}} credits</router-link>
            <img class="avatar" :src="db.profile.me.image" alt="">
            <p @click="logout" class="item">logout</p>
            <Mode :dark="db.settings.dark" @change="db.settings.setMode($event)"></Mode>
            <router-link v-if="db.profile.admin" class="item" to="/admin/users">📦</router-link>
        </div>

         <div v-else class="ch right">
            <router-link class="item" to="/welcome">{{db.profile.me.id}}</router-link>
        </div> -->

        <!-- result preview -->
    

    </div>
    <Popup center fixed relative v-if="showSupport" :z="1000" @close="showSupport = false; message=undefined; sent = false">
        <!-- <template #header>Support</template> -->
        <div v-if="!sent" class="column w500 center">
            <h5>Feel free to send us a message</h5>
            <Text v-model="message"></Text>
            <br>
            <Buttons @click="sendSupport()">Send</Buttons>
        </div>
        <div v-if="sent" class="column w500 center">
            <h5>Thank you! We'll contact you as soon as possible.</h5>
            <br>
            <Buttons @click="showSupport = false; sent = false; message=undefined;">OK</Buttons>
        </div>
    </Popup>
</template>
<script>
import { useI18n } from 'vue-i18n'
export default {
    data: function () {return {
        message: undefined,
        showSupport: false,
        sent: false,
    }},
    setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'local'
        })
        return { t }
    },
    components:{},
    props:{
        
    },
    methods: {
        async logout(){
            await this.db.profile.logout()
            this.tools.refresh()
        },
        async sendSupport(){
            let res = await this.api.support(this.message)
            if (!res || res.error){
                alert("Sorry, something wrong. Please try again later or send message to support@reshaper.ai")
            }
            
            this.message = undefined
            this.sent = true
        },
    },
}
</script>

<i18n>
{
  "en": {
    "home": "Home",
    "search": "Search",
    "people": "People",
    "top": "Top 100",
    "wait": "Waitlist",
  }
}
</i18n>

<style scoped>
.menu{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    background: none;
    margin-bottom: 0px;
    padding: 12px 3vw 12px 10vw;
    /* border-bottom: 0.5px solid var(--g1); */
    /* z-index: 100;
    background: var(--back);
    background: rgba(255, 255, 255, 0.00001);
    -webkit-backdrop-filter: saturate(180%) blur(40px);
    backdrop-filter: saturate(180%) blur(40px);
    z-index: 1000; */
}

.item{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: 0.01em;
    /* text-transform: capitalize; */
    color: var(--text);
    margin: 0 10px;
    cursor: pointer;

}
.item:hover{
    opacity: .6;
}
.item.router-link-exact-active{
    color: var(--g2);
}
.avatar{
    width: 28px;
    height: 28px;
    border-radius: 100px;
}
</style>

