// internal
import App from '@/page/app.vue';
import i18n from '@/ts/i18n';
import router from '@/ts/router';


import { ProfileStore } from '@/ts/store/profile';
import { SettingsStore } from '@/ts/store/settings';
import { createPinia } from 'pinia';
import { createApp } from 'vue';

// plugins
import JsonEditorVue from 'json-editor-vue';
import CodeEditor from 'simple-code-editor';
import { plugin as VueInputAutowidth } from 'vue-input-autowidth';
import vue3GoogleLogin from 'vue3-google-login';
  
// api
import api from '@/ts/api';
import type { File as resFile } from '@/ts/file';
import dict from '@/ts/store/dict';
import tools from '@/ts/tools.js';

// local
import ChannelsList from '@/ui/channelsList.vue';
import ReadComment from '@/ui/comment.vue';
import CreateNews from '@/ui/createnews.vue';
import NewsCard from '@/ui/newscard.vue';
import PreviewFeed from '@/ui/previewfeed.vue';
import PreviewStory from '@/ui/previewstory.vue';

// header
import Footer from '@/page/footer.vue';
import Head from '@/page/head.vue';

// ui framework
import Box from '@/ui/box.vue';
import Button from '@/ui/button.vue';
import Checkbox from '@/ui/checkbox.vue';
import Compare from '@/ui/compare.vue';
import Down from '@/ui/down.vue';
import Drop from '@/ui/drop.vue';
import Error from '@/ui/error.vue';
import Fix from '@/ui/fixbox.vue';
import Column from '@/ui/fixcolumn.vue';
import Gap from '@/ui/gap.vue';
import Input from '@/ui/input.vue';
import Mode from '@/ui/mode.vue';
import Popup from '@/ui/popup.vue';
import Row from '@/ui/row.vue';
import Scroll from '@/ui/scroll.vue';
import Status from '@/ui/status.vue';
import Switcher from '@/ui/switcher.vue';
import Tab from '@/ui/tab.vue';
import Tabs from '@/ui/tabs.vue';
import Text from '@/ui/text.vue';

//new
import Skeleton from '@/ui/skeleton.vue';

// bout
import Card from '@/ui/card.vue';
import Progress from '@/ui/progress.vue';
import User from '@/ui/user.vue';


// app
const app = createApp(App)

// i18n
app.use(i18n)

// pinia
app.use(createPinia())

//google
app.use(vue3GoogleLogin, {
  clientId: '1098169090906-f26dj1m2901h9bukt4npnkhblcdkovmn.apps.googleusercontent.com'
})


// stores
app.config.globalProperties.db = {}
app.config.globalProperties.db.settings = SettingsStore()
app.config.globalProperties.db.profile = ProfileStore()

// is dev
app.config.globalProperties.dev = process.env.NODE_ENV == 'development'

// router
app.use(router);
router.isReady().then(() => {app.mount('#app')});

// router helper
app.config.globalProperties.go = function(name: string) {router.push({name:name})}
app.config.globalProperties.is = function(name: string) {return router.currentRoute.value.name == name}
app.config.globalProperties.iss = function(name: string) {return router.currentRoute.value.name?.toString().startsWith(name)}
app.config.globalProperties.openlink = function(link: string) {window.open(link, '_blank');}


app.use(VueInputAutowidth)



 
// framework
app.component('Box', Box);
app.component('Column', Column);
app.component('Row', Row);
app.component('Gap', Gap);
app.component('Buttons', Button);
app.component('Switcher', Switcher);
app.component('Checkbox', Checkbox);
app.component('Head', Head);
app.component('Card', Card);
app.component('User', User);
app.component('Progress', Progress);
app.component('Footer', Footer);
app.component('Mode', Mode);
app.component('Popup', Popup);
app.component('Scroll', Scroll);
app.component('Down', Down);
app.component('Fix', Fix);
app.component('Text', Text);
app.component('Input', Input);
app.component('Tabs', Tabs);
app.component('Tab', Tab);
app.component('Drop', Drop);
app.component('Error', Error);
app.component('Status', Status);

//external
app.component('Compare', Compare);
app.component("Skeleton", Skeleton);
app.component("JSON", JsonEditorVue);
app.component("Code", CodeEditor);

// read
app.component('CreateNews', CreateNews);
app.component('NewsCard', NewsCard);
app.component('PreviewFeed', PreviewFeed);
app.component('PreviewStory', PreviewStory);
app.component('Comment', ReadComment);
app.component('ChannelsList', ChannelsList);

//app.component('sellect', Sellect);


// api
app.config.globalProperties.api = api

// tools
app.config.globalProperties.tools = tools

// dict
app.config.globalProperties.dict = dict

// clipboard
app.config.globalProperties.copy = async function(text: string) { await navigator.clipboard.writeText(text)},

// dict
app.config.globalProperties.dept = [
  { id: 'home', link:'/'},
  // { id: 'channels', link:'/channels'},
  // { id: 'news', link:'/news'},
  // { id: 'media', link:'/media'},
  // { id: 'users', link:'/users'},
  // { id: 'team', link:'/team'},
  // { id: 'comments', link:'/comments'},
  // { id: 'experts', link:'/experts'},
  // { id: 'support', link:'/support'},
  // { id: 'finance', link:'/finance'},
  // { id: 'servers', link:'/servers'},
  // { id: 'metrics', link:'/metrics'}, 
  // { id: 'summary', link:'/summary'}, 
]


// <p v-parallax="3">secrets</p>
app.directive('parallax', function (el, binding) {
  window.addEventListener('scroll', function(){
    const scrollValue = window.pageYOffset;
    let x = 3
    if (binding.value) x = binding.value
    el.style.transform = 'translateY('+(-scrollValue/x)+'px)';
    
    if (binding.modifiers.opacity){
      el.style.opacity = 1- Math.sqrt(scrollValue/2000);
    }
  });
})

document.addEventListener("click", function(event){
    app.config.globalProperties.xx = event.clientX
    app.config.globalProperties.yy = event.clientY
});


//app.directive('upload',upload)
app.directive('upload', function (el, binding) {
      
   /*  

        <p v-upload.txt.csv="uploadHandler(file)"></p>        

        v-upload.png.jpg="uploadHandler(file)"
        file {
            origin file
            res [bytes, base64, text]
            body ...
        }
        docs: txt,csv
        images: jpg, png, gif
        svg
        archive: zip, rar, gzip
    
    */
   
  if (window.FileReader && window.Blob) {
      // All the File APIs are supported.
  } else {
      // File and Blob are not supported
  }

  

  const onchangeHandler = (e:Event) => {

      const target= e.target as HTMLInputElement;
      const file: File = (target.files as FileList)[0];
      const resfile = <resFile>{}
      resfile.name = file.name
      const reader = new FileReader();
      if (binding.modifiers.text){
          resfile.format = 'text'
          reader.readAsText(file);
      } else if (binding.modifiers.base64){
        resfile.format = 'base64'
          reader.readAsDataURL(file);
      } else if (binding.modifiers.bytes){
        resfile.format = 'bytes'
          reader.readAsArrayBuffer(file);
      } else {
        resfile.format = 'text'
        reader.readAsText(file);
      }
      reader.onload = e =>{
        if (!e.target) return
        resfile.body = e.target.result
        binding.value(resfile)
      };
  }

  const accept = []

  // images
  if (binding.modifiers.jpg || binding.modifiers.images) accept.push(".jpg,.jpeg")
  if (binding.modifiers.png || binding.modifiers.images) accept.push(".png")
  if (binding.modifiers.gif || binding.modifiers.images) accept.push(".gif")
  
  // vector
  if (binding.modifiers.svg) accept.push(".svg")
  
  // video
  if (binding.modifiers.video) accept.push("video/*")

  // audio
  if (binding.modifiers.audio) accept.push("audio/*")
  
  // docs
  if (binding.modifiers.csv || binding.modifiers.docs) accept.push(".csv")
  if (binding.modifiers.txt || binding.modifiers.docs) accept.push(".txt")
  if (binding.modifiers.pdf || binding.modifiers.docs) accept.push(".pdf")
  if (binding.modifiers.doc || binding.modifiers.docs) accept.push(".doc")
  if (binding.modifiers.doc || binding.modifiers.docs) accept.push(".docx")
  if (binding.modifiers.xls || binding.modifiers.docs) accept.push(".xls") 
  if (binding.modifiers.xls || binding.modifiers.docs) accept.push(".xlsx")

  // archive
  if (binding.modifiers.zip   || binding.modifiers.archive) accept.push(".zip")
  if (binding.modifiers.rar   || binding.modifiers.archive) accept.push(".rar")
  if (binding.modifiers.gzip  || binding.modifiers.archive) accept.push(".gzip")
  
  

  // input
  const id = Math.round(Math.random()*1000)
  const input = document.createElement('input')
  input.type = "file";
  input.id = id.toString()
  input.style.position = 'absolute'
  input.style.display = 'none'
  input.style.left = '-100vw'
  input.accept = accept.join(',')
  input.onchange = onchangeHandler

  // input append
  
  const body = document.createElement('p')//  document.getElementById('app')
  body.appendChild(input)

  // upload
  const handler = () => {
      input.click()
  }

  el.addEventListener('click', handler);

})





