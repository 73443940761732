import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';


export const SettingsStore = defineStore('settings', {
  state: () => ({ 
    dark      : useStorage('reshape.dark', false),
    lang      : useStorage('reshape.lang', 'en'),
  }),
  
  getters: {
      
  },
  actions: {
    setMode(dark){
      this.dark = dark
    }, 
  },
})

