import { ProfileStore } from '@/ts/store/profile';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';


const routes: Array<RouteRecordRaw> = [
  //public
  
  {path: '/welcome',  name: 'welcome',    beforeEnter: [publics], component: () => import('@/page/landing.vue')},
  
  //private
  {path: '/',         name: 'home',       beforeEnter: [privates], component: () => import('@/page/home.vue')},
  {path: '/credits',  name: 'credits',    beforeEnter: [privates], component: () => import('@/page/credits.vue')},
  
  //ai pages
  {path: '/clone',  name: 'ai.clone',   beforeEnter: [privates], component: () => import('@/page/products/clone.vue')},
  {path: '/text',   name: 'ai.text',    beforeEnter: [privates], component: () => import('@/page/products/text.vue')},
  {path: '/remove', name: 'ai.remove',  beforeEnter: [privates], component: () => import('@/page/products/remove.vue')},
  {path: '/scale',  name: 'ai.scale',   beforeEnter: [privates], component: () => import('@/page/products/scale.vue')},
  {path: '/qr',  name: 'ai.qr',   beforeEnter: [privates], component: () => import('@/page/products/qr.vue')},
  
   // air
   {path: '/admin', name: 'admin',  beforeEnter: [privates, admin], component: () => import('@/page/admin/index.vue'),
    children: [

        //admin general
        {path: 'users',   name: 'admin/users',   beforeEnter: [privates,admin], component: () => import('@/page/admin/users.vue')},
        {path: 'credits',   name: 'admin/credits',   beforeEnter: [privates,admin], component: () => import('@/page/admin/credits.vue')},
        {path: 'logs',    name: 'admin/logs',   beforeEnter: [privates,admin], component: () => import('@/page/admin/index.vue')},
        
        //ai
        {path: 'ai', name: 'admin/ai',   beforeEnter: [privates,admin], component: () => import('@/page/admin/ai/index.vue'),
          children: [
            {path: 'text',    name: 'admin/ai/text',   beforeEnter: [privates,admin], component: () => import('@/page/admin/ai/text.vue')},
            {path: 'scale',   name: 'admin/ai/scale',  beforeEnter: [privates,admin], component: () => import('@/page/admin/ai/index.vue')},
            {path: 'clone',   name: 'admin/ai/clone',  beforeEnter: [privates,admin], component: () => import('@/page/admin/ai/index.vue')},
            {path: 'remove',  name: 'admin/ai/remove', beforeEnter: [privates,admin], component: () => import('@/page/admin/ai/index.vue')},
            {path: 'id',      name: 'admin/ai/id',     beforeEnter: [privates,admin], component: () => import('@/page/admin/ai/index.vue')},
            {path: 'other',   name: 'admin/ai/other',  beforeEnter: [privates,admin], component: () => import('@/page/admin/ai/index.vue')},
        ]
      },
        
        
    ]
  },

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



function publics(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (!profile.id){
    next()
  } else{
    next({ name: 'home'})
  }
}

function admin(to: any, from: any, next: any) {
  const db = ProfileStore()
  if (db.admin){
    next()
  } else{
    next({ name: 'home'})
  }
}



function privates(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (profile.id){
    next()
  } else{
    next({ name: 'welcome' })
  }
}

function loggedNotInit(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (profile.id && !profile.name){
    next({ name: 'init' })
  } else{
    next()
  }
}

// init profile
function init(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (to.name=='init'){
    if (!profile.id){
      next({ name: 'welcome'})
      return
    }
    if (profile.id && profile.name){
      next({ name: 'profile'})
      return
    }
    if (profile.id && !profile.name){
      next()
      return
    }
  } else {
    if (!profile.id){
      next({ name: 'welcome'})
      return
    }
    if (profile.id && profile.name){
      next()
      return
    }
    if (profile.id && !profile.name){
      next({ name: 'init'})
      return
    }
  }
}

export default router
